import './houtmaat.scss';
import Instafeed from './lib/js/instafeed.js';
import '@mod-mso_pixelarity/webdesigns/vrederick2/vrederick2';
import * as whintegration from "@mod-system/js/wh/integration";
import * as dompack from 'dompack';

dompack.onDomReady(() => {

  let accesstoken = whintegration.config.obj.accesstoken;
  let userid = whintegration.config.obj.userid;

  if(accesstoken !== "" && userid !== "")
  {
    var userFeed = new Instafeed({
      get: 'user',
      userId: userid,
      limit: 5,
      accessToken: accesstoken,
      template: '<a target="_blank" href="{{link}}"><img title="{{caption}}" src="{{image}}" /></a>'
    });
    userFeed.run();
  }

});
